import React, {useState} from "react";
import { getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { ApiKey } from "../../util/Constant";

/**
 * Author: -
 */
const Input = React.forwardRef((props, ref) => {
	const {
		name,
		containerClassName = "",
		labelClassName = "",
		inputClassName = "",
		type = "text",
		label,
		small,
		placeholder = "",
		errors,
		disabled,
		inputGroupType,
		inputGroupContent,
		onChange,
		...rest
	} = props;
	const { t, i18n } = useTranslation();
	
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<div className={"form-group" + containerClassName}>
				{label && (
					<label className={labelClassName}>
						<b>{label}</b>
					</label>
				)}
				{inputGroupType ? (
					<div className="input-group mb-1">
						{inputGroupType == "prepend" && (
							<div className={`input-group-${inputGroupType}`}>
								<span className="input-group-text">{inputGroupContent}</span>
							</div>
						)}
						<input
							name={name}
							ref={ref}
							type={type=="password"? (showPassword ? "text" : "password"):type}
							className={"form-control " + inputClassName}
							placeholder={placeholder}
							onChange={onChange}
							{...rest}
						/>
						{inputGroupType == "append" && (
							<div className={`input-group-append`}>
								<span className="input-group-text">{inputGroupContent}</span>
							</div>
						)}
					</div>
				) : !disabled ? (
					<input
						name={name}
						ref={ref}
						type={type=="password"? (showPassword ? "text" : "password"):type}
						className={"form-control " + inputClassName}
						placeholder={placeholder}
						onChange={onChange}
						{...rest}
					/>
				) : (
					<div className="form-disabled">{props.value}</div>
				)}
				{type=="password" && <span onClick={togglePasswordVisibility} className="password-toggle-icon">
					{showPassword ? <i class="fa fa-eye-slash"></i>: <i class="fa fa-eye"></i>}
					</span>}
			</div>
			{small && <div className="small" style={{ color: "red", paddingBottom:"10px" }}>{small}</div>}
			{errors
				? errors[name] && (
						<div className="d-flex align-items-center user-available mb-2">
							<div className="first">
								<img
									src={require("../../assets/img/new_icons/red.png")}
									className="img-responsive"
									alt="check"
								/>
							</div>
							<div className="font12 text-lgray3 second text-customred">
								{stringIsNullOrEmpty(errors[name][ApiKey._API_MESSAGE_KEY])
									? t(getValidationMessage(errors[name]))
									: t(errors[name][ApiKey._API_MESSAGE_KEY])}
							</div>
						</div>
				  )
				: ""}
		</>
	);
});

export default Input;
