import React from "react";
import { Link } from "react-router-dom";
import { AlertTypes, WebUrl } from "../../util/Constant";
import i18n from "../../localization/i18n";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showMessage } from "redux/AppAction";
class Footer extends React.Component {
  navigateLiveChat = () => {
    this.props.history.push('/livechat'); // Use history from props
  };

  constructor(props) {
    super(props);

  
    this.state = {
      activeTab: localStorage.getItem("defaultActiveNav"),
      totalUnreadLiveChat: localStorage.getItem("totalUnreadLiveChat"),
      bottomNav: [
        {
          title: "HOME",
          link: WebUrl._URL_DASHBOARD,
          iconName: themeIcons.home,
          subLink: [
            {
              link: WebUrl._URL_DEPOSIT,
            },
            {
              link: WebUrl._URL_TRANSACTION_HISTORY,
            },
            {
              link: WebUrl._URL_SHAREHOLDER,
            },
            {
              link: WebUrl._URL_KPI,
            },
            {
              link: WebUrl._URL_TRANSFER,
            },
            {
              link: WebUrl._URL_COMMISSION,
            },
            {
              link: WebUrl._URL_MAIN_WALLET,
            },
            {
              link: WebUrl._URL_WITHDRWAL_METHODS,
            },
            {
              link: WebUrl._URL_RELOAD_BANK,
            },
            {
              link: WebUrl._URL_WITHDRWAL_BY_BANK_TRANSFER,
            },
          ],
        },
        {
          title: "ACTIVITY",
          link: WebUrl._URL_REPORTS_OVERVIEW,
          iconName: themeIcons.activity,
          subLink: [
            {
              link: WebUrl._URL_WALLET_HISTORY,
            },
            {
              link: WebUrl._URL_TRANSACTION_HISTORY,
            },
            {
              link: WebUrl._URL_MEMBERS_TRANSFER,
            },
            {
              link: WebUrl._URL_COMMISSION_HISTORY,
            },
            {
              link: WebUrl._URL_GAME_WALLET_TRANSFER,
            },
          ],
        },
        {
          title: "PROMOTIONS",
          link: "/fs-promotion",
          iconName: themeIcons.dashboardAnnouncement,
          subLink: [],
        },
        {
          title: "DEPOSIT",
          link: "/deposit",
          iconName: themeIcons.wallet,
          subLink: [],
        },
        {
          title: "ACCOUNT",
          link: "/settings",
          iconName: themeIcons.account,
          subLink: [],
        },
      ],
    };
  }

  componentWillUpdate() {
    {
      this.state.bottomNav.map((item, index) => {
        if (item.link.match(window.location.pathname) != null) {
          localStorage.setItem("defaultActiveNav", index);

          if (localStorage.getItem("defaultActiveNav") == index) {
            localStorage.setItem("defaultActiveNav", index);
          }
        }

        {
          item.subLink.map((subItem, subIndex) => {
            if (subItem.link.match(window.location.pathname) != null) {
              if (localStorage.getItem("defaultActiveNav") == index) {
                localStorage.setItem("defaultActiveNav", index);
              }
            }
          });
        }
      });
    }
  }

  render() {
    return (
      <div id="footer" className="footer">
        <div className="floating-button" style={{display:(window.location.pathname !== '/livechat'?'block':'none')}} onClick={() => {this.navigateLiveChat()}}></div>
        <div className="bottom-navigator-tab">
          {this.state.bottomNav.map((item, index) => {
            return (
              <Link
                key={index}
                className={`bottom-btn-wrapper${item.title === "PROMOTIONS" ? " promotions-nav-button" : ""}`}
                to={location => {
                  if (this.props.authState.isLoggedIn) {
                    return {
                      ...location,
                      pathname: item.link,
                    };
                  } else {
                    if (item.title === 'ACTIVITY' || item.title === "DOWNLINES") {
                      // Handle login redirection
                    } else {
                      return {
                        ...location,
                        pathname: item.link,
                      };
                    }
                  }
                }}
                onClick={(e) => {
                  localStorage.setItem("defaultActiveNav", index);
                  this.setState(() => ({ activeTab: index }));
                  if (!this.props.authState.isLoggedIn && (item.title === 'ACTIVITY' || item.title === "DOWNLINES")) {
                    this.props.dispatch(showMessage({
                      type: AlertTypes._WARNING,
                      content: i18n.t("PLEASE_LOGIN_FIRST"),
                    }));
                  }
                }}
                style={{
                  backgroundColor:
                    localStorage.getItem("defaultActiveNav") == index
                      ? "#131D35"
                      : "#0F172A",
                }}
              >
                <div
                  className={
                    localStorage.getItem("defaultActiveNav") == index
                      ? "icon svg-icon-active"
                      : "icon svg-icon"
                  }
                >
                  <VIcon
                    icon={item.iconName}
                    className="theme-icon-blue-size-20"
                  />
                  {localStorage.getItem("totalUnreadLiveChat") > 0 && item.title === 'LIVECHAT' && (
                    <span className="badge-live-chat">{localStorage.getItem("totalUnreadLiveChat")}</span>
                  )}
                </div>

                <div
                  className={
                    localStorage.getItem("defaultActiveNav") == index
                      ? "icon-title active"
                      : "icon-title"
                  }
                >
                  {i18n.t(item.title)}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state,
});

// export default Footer;
export default connect(mapStateToProps, null)(withRouter(Footer));
